import ProductCardRenderer from "@/Renderers/ProductCardRenderer";
import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import ProductCard from "./ProductCard";

interface RenderProductCardProps {
  product: FormattedDataType["products"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderProductCard: React.FC<RenderProductCardProps> = ({
  product,
  section,
  isSliderSection,
}) => {
  return (
    <SectionCardWrapper
      data-test="product-card"
      background={section?.background}
      isSlider={isSliderSection}
      isProduct
    >
      <ProductCardRenderer
        Component={ProductCard}
        product={product}
        onlyCardBody
        isSlider={isSliderSection}
      />
    </SectionCardWrapper>
  );
};

export default RenderProductCard;
