import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Section } from "@/shared/layout";
import type { HomeGallerySectionProps } from "../../types";
import { ArrowRight } from "@/assets/Icons/ArrowRight";
import { FlexRow } from "@/shared/globals";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import { DisplayAsEnum, SectionTypeEnum } from "@/generated/graphql";
import SectionGenerator from "../components/HomeGallerySection/SectionGenerator";
import Flex from "@/shared/globals/UiElements/Flex";
import { useStore } from "@/lib/storeData/StoreContext";
import { Breakpoints } from "@/shared/globals/types";
import WhileInViewAnimation from "@/shared/globals/WhileInViewAnimation";
import { getColumnCount } from "@/shared/utils/homeHelpers";
import SectionHeading from "./SectionHeading";
import GridGallerySection from "./GridGallerySection";
import ReactSwiperCss from "@/shared/globals/LazyElements/react-swiper";

const HomeGallerySection: React.FC<HomeGallerySectionProps> = ({
  section,
  index,
}) => {
  const { isTablet } = useBreakPoints();
  const [prevEl, setPrevEl] = useState<HTMLSpanElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLSpanElement | null>(null);
  const { appearance } = useStore();
  const { columnCount, itemsProvidedCount } = getColumnCount(section);
  const isOneItemProvided = itemsProvidedCount === 1;
  const isTabletAndNotProductsRow =
    isTablet && section?.type !== SectionTypeEnum.ProductsRow;
  const isTabletAndSliderProductsRow =
    isTablet &&
    section?.type === SectionTypeEnum.ProductsRow &&
    section?.displayAs === DisplayAsEnum.Slider;

  const slidesPerView = isTablet
    ? isOneItemProvided
      ? 1
      : 1.5
    : section.type === SectionTypeEnum.ProductsRow
      ? Math.max(section?.itemsPerRow!, 3)
      : section.itemsPerRow;
  const { isRTL } = useTheme();

  return (
    <StyledSection
      stretchToFullWidth={
        (isTabletAndNotProductsRow && !isOneItemProvided) ||
        section?.stretchToFullWidth ||
        (isTabletAndSliderProductsRow && !isOneItemProvided)
      }
      isFirst={index === 0}
      isBanner={section?.type === SectionTypeEnum.Banner}
      is1ItemPerRow={section.itemsPerRow === 1}
      is1ItemProvided={isOneItemProvided}
      isFullWidth={section.stretchToFullWidth}
      isTitleHidden={!section.showSectionHeader}
    >
      <SectionHeading section={section} />
      {section.displayAs === DisplayAsEnum.Slider ||
      isTabletAndNotProductsRow ? (
        <WhileInViewAnimation>
          <SliderContainer
            className="relative"
            primaryColor={appearance?.colors?.primary!}
          >
            <ReactSwiperCss />
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={16}
              centeredSlides={isTablet}
              navigation={{ prevEl, nextEl }}
              modules={[Navigation, Pagination, Autoplay]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              loop={isTablet || columnCount >= section.itemsPerRow!}
              style={{
                width: "100%",
              }}
              autoplay={{
                delay: 4000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
            >
              {SectionGenerator({ section, isSliderSection: true })}
            </Swiper>
            {!isTablet && itemsProvidedCount > section?.itemsPerRow! && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                className={`absolute -bottom-[10px] left-1/2 -translate-x-1/2 z-TOOLTIP ${
                  isRTL ? "rotate-180" : ""
                }`}
                width="120px"
              >
                <div
                  className="text-gray-400 hover:text-gray-700 p-xs cursor-pointer"
                  ref={(node) => {
                    if (isRTL) setNextEl(node);
                    else setPrevEl(node);
                  }}
                >
                  <ArrowRight rotate={!isRTL} />
                </div>
                <div
                  className="text-gray-400 hover:text-gray-700 p-xs cursor-pointer"
                  ref={(node) => {
                    if (isRTL) setPrevEl(node);
                    else setNextEl(node);
                  }}
                >
                  <ArrowRight rotate={isRTL} />
                </div>
              </Flex>
            )}
          </SliderContainer>
        </WhileInViewAnimation>
      ) : (
        <>
          <GridGallerySection
            isProduct={section?.type === SectionTypeEnum.ProductsRow}
            itemsPerRow={section.itemsPerRow}
          >
            <SectionGenerator section={section} />
          </GridGallerySection>
        </>
      )}
    </StyledSection>
  );
};

export default HomeGallerySection;

/**
 *
 * Styles
 *
 */

const StyledSection = styled(Section)<{
  stretchToFullWidth?: boolean;
  isFirst: boolean;
  isBanner: boolean;
  is1ItemPerRow: boolean;
  is1ItemProvided: boolean;
  isFullWidth?: boolean;
  isTitleHidden: boolean;
}>`
  && {
    max-width: ${({ stretchToFullWidth }) => stretchToFullWidth && "100vw"};
    padding: ${({ stretchToFullWidth }) => stretchToFullWidth && "unset"};
  }
  margin: 48px auto;
  margin-top: ${({
    isFirst,
    isBanner,
    is1ItemPerRow,
    isFullWidth,
    isTitleHidden,
  }) =>
    isFirst && isBanner && is1ItemPerRow && isFullWidth && isTitleHidden
      ? "0px"
      : "48px"};
  position: relative;
  @media (max-width: ${Breakpoints["TABLET"]}px) {
    margin-top: ${({ isFirst, isBanner, is1ItemProvided }) =>
      isFirst && isBanner && is1ItemProvided ? "0px" : "48px"};
  }
`;

const SliderContainer = styled(FlexRow)<{
  primaryColor?: string;
}>`
  .swiper-pagination {
    @media (max-width: ${Breakpoints["TABLET"]}px) {
      display: none;
    }
  }
  .swiper-pagination-bullets-dynamic {
    bottom: 0px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ primaryColor }) => primaryColor};
  }

  .swiper-wrapper {
    margin-bottom: 24px;
  }
`;
