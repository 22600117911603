import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import CollectionCard from "./CollectionCard";
import { sectionMaxHeight } from "@/shared/utils/section-max-height";
import { sectionAspectRatio } from "@/shared/utils/aspect-ratio";

interface RenderCollectionCardProps {
  collection: FormattedDataType["collections"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
  isOneElement?: boolean;
}

const RenderCollectionCard: React.FC<RenderCollectionCardProps> = ({
  collection,
  section,
  isSliderSection,
  isOneElement,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="collection-card"
      background={section?.background}
      isCollection
      aspectRatio={sectionAspectRatio(collection.image, isOneElement)}
      maxHeight={sectionMaxHeight(collection.image, isOneElement)}
    >
      <CollectionCard
        collection={collection}
        hideTitle={!section?.showItemName}
      />
    </SectionCardWrapper>
  );
};

export default RenderCollectionCard;
