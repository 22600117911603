import { FC } from "react";
import { useLocale } from "@/i18n/i18n-client";
import { SectionHeadingProps } from "../../types";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { code: activeLocale } = useLocale();
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <p className="text-[36px] max-md:text-[24px] font-semibold text-text-default mb-xl max-md:mb-2xl max-md:text-center">
      {translations?.header || section?.header}
    </p>
  ) : null;
};

export default SectionHeading;
