import React from "react";
import dynamic from "next/dynamic";
import { FormattedDataType } from "@/templates/types";
import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

interface VideoCardProps {
  isSliderSection?: boolean;
  section: Partial<FormattedDataType>;
  link: FormattedDataType["links"][0];
}

const VideoCard: React.FC<VideoCardProps> = ({
  section,
  isSliderSection,
  link,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="link-card"
      background={section?.background}
    >
      <ReactPlayer url={link} width="100%" height="350px" />
    </SectionCardWrapper>
  );
};

export default VideoCard;
