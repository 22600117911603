import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";
import SectionCardWrapper from "../elements/SectionCardWrapper";
import ProductCard from "../elements/ProductCard";
import AttributeValueCard from "../elements/AttributeValueCard";
import BannerCard from "../elements/BannerCard";
import CollectionCard from "../elements/CollectionCard";

// default
import SectionContent from "@/templates/default/elements/SectionContent";
import PageHeader from "@/templates/default/elements/PageHeader";

// default

const elements = {
  SectionCardWrapper,
  ProductCard,
  AttributeValueCard,
  BannerCard,
  CollectionCard,
  SectionContent,
  PageHeader,
};

export function StorePagesLoader(props: { children: ReactNode }) {
  StoreTemplate.load("arab-hardware", "store", elements);
  return props.children;
}
