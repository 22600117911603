import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import BannerCard from "./BannerCard";
import { TResponsiveAspectRatio } from "@/shared/utils/aspect-ratio";
import useBreakPoints from "@/shared/utils/useBreakPoints";

interface RenderBannerCardProps {
  banner: FormattedDataType["banners"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
  aspectRatio: TResponsiveAspectRatio;
}

const RenderBannerCard: React.FC<RenderBannerCardProps> = ({
  banner,
  section,
  isSliderSection,
  aspectRatio,
}) => {
  const { isTablet } = useBreakPoints();
  const removeBorderRadiusDesktop =
    section.itemsPerRow === 1 && section.stretchToFullWidth;
  const removeBorderRadiusMobile = section.banners?.length === 1;

  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="banner-card"
      background={section?.background}
      removeBorderRadiusDesktop={removeBorderRadiusDesktop}
      removeBorderRadiusMobile={removeBorderRadiusMobile}
      aspectRatio={isTablet ? aspectRatio.mobile : aspectRatio.desktop}
    >
      <BannerCard banner={banner} />
    </SectionCardWrapper>
  );
};

export default RenderBannerCard;
