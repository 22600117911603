import React from "react";
import { Photo } from "@/shared/globals/UiElements/Photo";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import { LinkType } from "@/generated/graphql";
import { BannerCardProps } from "../../../../types";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";
import { getLink } from "@/shared/utils/getLink";
import { Link } from "@/i18n/i18n-navigation";

const BannerCard: React.FC<BannerCardProps> = ({ banner }) => {
  const { isTablet } = useBreakPoints();
  if (banner?.bannerLink?.type === LinkType.None)
    return <BannerImage banner={banner} isTablet={isTablet} />;
  return (
    <Link
      fullHeight
      fullWidth
      href={
        banner?.bannerLink?.type === LinkType.ExternalLink
          ? banner?.bannerLink?.url!
          : getLink(
              banner?.bannerLink?.type,
              banner?.bannerLink?.resource?.handle
            )
      }
      target={
        banner?.bannerLink?.type === LinkType.ExternalLink ? "_blank" : "_self"
      }
    >
      <BannerImage banner={banner} isTablet={isTablet} />
    </Link>
  );
};

interface BannerImageProps {
  banner: BannerCardProps["banner"];
  isTablet: boolean;
}

const BannerImage = ({ banner, isTablet }: BannerImageProps) => (
  <div className="w-full h-full">
    <MotionElementWrapper
      fullWidth
      fullHeight
      whileHover={{ scale: 1.1 }}
      duration={0.5}
    >
      <Photo
        src={
          (isTablet
            ? banner?.mobileImage?.src || banner?.image?.src
            : banner?.image?.src || banner?.mobileImage?.src) ||
          "/default-placeholder-image.png"
        }
        objectFit="cover"
      />
    </MotionElementWrapper>
  </div>
);

export default BannerCard;
